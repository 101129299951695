<script setup lang="ts">
import { FormKitLazyProvider } from '@formkit/vue'
import type { InputsSelectProps } from './InputsSelect.props'

const props = defineProps<InputsSelectProps>()

const moveLabelInsideInput = () => {
  // get element by id
  const selectElement = document.getElementById(props.id)

  if (!selectElement) return

  // select the first parent with class .formkit-outer
  const outerElement = selectElement.parentElement?.parentElement?.parentElement

  if (!outerElement) return

  const labelElement = outerElement.querySelectorAll('label')

  const getTarget = outerElement.querySelectorAll('.formkit-selector')

  // Get the first child of targetElement (if any)
  const firstChild = getTarget[0].firstChild

  // Insert elementToMove before the first child (or null if no first child)
  getTarget[0].insertBefore(labelElement[0], firstChild)
}

const addAriaHiddenToSelectIcon = () => {
  // get element by id
  const selectElement = document.getElementById(props.id)

  if (!selectElement) return

  // select the first parent with class .formkit-outer
  const outerElement = selectElement.parentElement?.parentElement?.parentElement

  if (!outerElement) return

  // Get select icon svg element
  const svg = outerElement.querySelector('.formkit-select-icon svg')

  // Add aria-hidden attribute if svg exists
  if (svg) {
    svg?.setAttribute('aria-hidden', 'true')
  }
}

onMounted(() => {
  addAriaHiddenToSelectIcon()
  moveLabelInsideInput()
})
</script>

<template>
  
<FormKitLazyProvider config-file="true">
<FormKit
    :id="id"
    type="dropdown"
    :name="name"
    :label="label"
    :value="value"
    :model-value="modelValue"
    :options="options"
    :select-icon="selectIcon ?? 'myChevronDown'"
    :floating-label="true"
    :deselect="false"
    :validation="validation"
    :validation-messages="validationMessages"
    :validation-rules="validationRules"
    :validation-visibility="validationVisibility"
    :disabled="disabled"
    role="combobox"
    :aria-activedescendant="true"
    :classes="{
      outer: disabled ? 'opacity-20' : '',
    }"
    @update:model-value="$emit('update:model-value', $event)"
  >
    <template #selectedIcon="context">
      <div
        class="border-stroke-primary group-aria-selected:bg-background-primary group-aria-selected:text-icon-secondary absolute left-2 top-3 flex h-4 w-4 shrink-0 items-center justify-center rounded-full border text-transparent"
        :class="{
          'bg-background-primary !text-icon-secondary':
            context.option.value === context.value,
          'opacity-0': disabled,
        }"
      >
        <DefaultIconsCheck aria-hidden="true" class="h-full w-full" />
      </div>
    </template>
  </FormKit>
</FormKitLazyProvider>

</template>

<style lang="scss" src="@design-system/assets/css/floatingLabelFormkit.scss" />
